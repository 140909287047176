@import '~bootstrap/scss/bootstrap'; 

// Variables
$enable-negative-margins: true;

// Mixins
@mixin baseline() {
    color: $white;
    block-size: fit-content;
    min-height: 100vh;
    z-index: 1;
    margin-top: 10em;

}

@mixin logo() {
    font-size: xx-large;
    color: $white; 
}

@mixin backLight() {
    box-shadow: 0px 0px 54px -4px rgba(255, 255, 255, 0.7);
}

// Keyframes
@keyframes movedown {
    0% {
        transform: translateY(-100px);
        visibility: visible;
    }

    100% {
        transform: translate(0);
        visibility: visible;
    }
}



#App {
    background-color: $gray-800;
    width: 100%;
    font-family: 'Times New Roman', Times, serif;
    z-index: -5;
    position: absolute;

    .fixed-background-container {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 2em);
        height: calc(100% - 2em);
        margin: 1em;
        opacity: 80%;
        background-color: $gray-900;
        z-index: -1; 
    }
}

// Main Section
#Main,
#About,
#Projects,
#VidDownloader,
#Contact,
#YouLost,
#ParkBuddy,
#DeepValueTrade,
#Vortex,
#mainJokes,
#VidDownloader {
    @include baseline;
    
}

#Main {
    .RotatingGlobe {
        overflow: hidden;
        // height: 50vh;
    }

    
}

#About {
    p {
        letter-spacing: 0.1em;
    }
}

#Projects {
    div p {}
}

#Map {
    .map-container {
        height: 400px;
    }
}

#VidDownloader {
    
}

#Contact {
    letter-spacing: 0.15em;
    line-height: 2;

    a {
        color: $white;
        transition: 0.5s;
        transition-property: transform;

        &:hover {
            color: $warning; 
            transform: scale(1.5);
        }
    }

    .alert {
        width: 50%;
        left: 25%;
    }
}

#mainJokes {
    height: 100vh;
}

#Footer {

    img {
        height: 35px;
    }

    h2 {
        @include logo;
    }

    div > a {
        color: $white;
        transition: 0.5s;
        transition-property: transform;
        letter-spacing: 0.2em;

        &:hover {
            color: $red; // Use Bootstrap's info color
            transform: scale(1.5);
        }
    }

    .disableLink {
        pointer-events: none;
    }

    .AllRights {
        color: $white;
    }

    .hack {
        border: 1px solid $white;
    }
}

nav {
    top: 0px;
    width: 100%;

    img {
        height: 35px;
    }

    h2 {
        @include logo;
    }

    ul {
        .nav-link {
            padding-right: 1em;
            font-size: small;
            color: $white;

            &:focus,
            &:hover {
                color: $red; 
            }
        }

        .vr {
            width: 1px;
        }
    }
}

.min100height {
    min-height: 100vh;
}

.spincube {
    height: 50vh;
    max-height: 900px;
}

/* Fade in from the left */
.fade-in-left {
  animation: fadeInLeft 0.8s ease-out forwards;
}

/* Fade in from the right */
.fade-in-right {
  animation: fadeInRight 0.8s ease-out forwards;
  opacity: 0;
  transform: translateX(-30px); /* Start from the left */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

/* When the element is visible, apply this class */
.fade-in-right.visible {
  opacity: 1;
  transform: translateX(0); /* Move to original position */
}

/* Keyframes for fading in from the left */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.navbar-light .navbar-nav .nav-link {
    color: #ffffff;
}

/* Keyframes for fading in from the right */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(245 45 45 / 70%);
}

.navbar {
    transition: background-color 0.5s ease, margin-top 0.5s ease;
    background-color: transparent;
    margin-top: 1em;
    z-index: 1030;
}

.navbar.scrolled {
    background-color: #343a40;
    margin-top: 0;
    transition: background-color 0.5s ease , margin-top 0.5s ease ;
}

.navbar:has(.show) {
    background-color: #343a40;
}


// Media Queries
// Small devices (landscape phones, 576px and down)
@media (max-width: 576px) {
    nav {
        img {
            margin-left: 10px;
        }

        .nav-link {
            margin-left: 0 !important;
        }
    }

    .navbar-toggler {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1;
        border-radius: 0.2rem;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// Z-Index Variables
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
